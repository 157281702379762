import React, { useEffect, useState } from 'react';
import InvoiceComponent from '../components/invoice/invoice';
import FormPayment from '../components/paymentForm/paymentForm';
import App from '../App';



const FormPaymentPage = () => {
  return (
    <div className="App">
       <div className="main-content">
            <FormPayment/>
        </div>
  </div>
  );
};

export default FormPaymentPage;
