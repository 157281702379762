import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const ButtonStyled = styled(Button)`
  background-color: ${props => (props.incactive ? 'red' : '#50fa7b')};
  color: #44475a;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
`;