const ART_CRY_LOLAKIS = ` 
#####  ######  #     #    #       ####### #          #    #    # ###  #####  
#     # #     #  #   #     #       #     # #         # #   #   #   #  #     # 
#       #     #   # #      #       #     # #        #   #  #  #    #  #       
#       ######     #       #       #     # #       #     # ###     #   #####  
#       #   #      #       #       #     # #       ####### #  #    #        # 
#     # #    #     #       #       #     # #       #     # #   #   #  #     # 
 #####  #     #    #       ####### ####### ####### #     # #    # ###  #####  
                                                                              
 `

const ART_PAYSANDU = `
@@@@@@@@@@@@@@@@@@@@(((((((((((((((((((((((((((((((((((((((((@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@(((((((((((((((((((((((((((((((((((((((((((((@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@(((((                                       (((((@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@(((((  (((  (((((((((((((((((((((((((((((  (((  (((((@@@@@@@@@@@@@
@@@@@@@@@@@@((((   (((((  (((((((((           (((((((((  (((((   ((((@@@@@@@@@@@
@@@@@@@@((((((   (((((((  (((((((((    ((( /(((((((((((  (((((((   ((((((@@@@@@@
@@@(((((((    ((((((((((  ((((((((((          (((((((((  ((((((((((    ((((((#@@
@(((((    ((((    ((((((  ((((((((((((((((     ((((((((  ((((((((  ((((    (((((
@(((  (((((    (    ((((  (((((((((     (     /((((((((  ((((          ((((  (((
@((((  ((*    ((   (((((  ((((((((((((     ((((((((((((  (((    (((((   ((  ((((
@@(((  ((((.    ,(((((((  (((((((((((((((((((((((((((((  (((    ((((((((((  (((@
@@((((  (((((     ((((((  (((((((((((((((((((((((((((((  ((((         (((  ((((@
@@@(((  (((((((  (((((((  (((((((((((((.(((((((((((((((  ((((((((, .(((((  (((@@
@@@@(((  (((   (((((((((((((((((((((((   (.  (((((((((((((((((((((((((((  (((@@@
@@@@((((  (((( (((((((((((((( ((((,         (((((  ((((((((((((((((( ((  ((((@@@
@@@@@((((  ((((((( (((((((((((((( ((       (( ((((((((((((((((((  ((((  ((((@@@@
@@@@@@((((  ((((((((  (((((((((((((       ((((((((((((((((((((( (((((  ((((@@@@@
@@@@@@@((((  (((((((((((((((   ((((       (((((((((((    (((((((((((  ((((@@@@@@
@@@@@@@@((((  ((((((((((  (((((((((((     (((((((((((((  ((((((((((  ((((@@@@@@@
@@@@@@@@@@(((   ((((((((  (((((((((((     (((((((((((((  ((((((((   (((@@@@@@@@@
@@@@@@@@@@@((((  (((((((  (((((((((        ((((((((((((  (((((((  ((((@@@@@@@@@@
@@@@@@@@@@@@((((   (((((  (((((((((((        ((((((((((  (((((   ((((@@@@@@@@@@@
@@@@@@@@@@@@@@((((  ((((  ((((((((((((         ((((((((  ((((  ((((@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@((((  ((  ((((((((((((((  ,   (((((((((  ((  ((((@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@((((    (((((((((((((((((((((((((((((    ((((@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@((((   (((((((((((((((((((((((((((   ((((@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@((((   (((((((((((((((((((((((  ,((((@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@(((((   (((((((((((((((((   (((((@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@(((((   (((((((((((   (((((@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@(((((   .(((    (((((@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@((((((   ((((((@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@(((((((@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
`

export default function easterEgg(){
    console.clear()
    console.log(ART_CRY_LOLAKIS);
    console.log(ART_PAYSANDU)
}