import { Route, Routes } from 'react-router-dom';
import FormPayment from "./../pages/formPayment";
import InvoicePage from '../pages/invoice';

const RoutesA = () => {

  return (
    <Routes>
        <Route path="/">
          <Route index element={<FormPayment />} />
          <Route path="invoice/:invoiceId" element={<InvoicePage />} />
        </Route>
      
    </Routes>
  );
}

export default RoutesA;