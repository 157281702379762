import { Button } from 'react-bootstrap';
import styled from 'styled-components';


export const FormContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 100%;
    border-radius: 0;
  }
`;

export const FormGroup = styled.div`
  margin-bottom: 20px;
`;

export const ButtonStyled = styled(Button)`
  background-color: ${props => (props.incactive ? 'red' : '#50fa7b')};
  color: #44475a;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
`;
