export const  TEXTS = {
    payerLink: "Account link that will pay",
    validLink: "All good",
    invalidLink: "That link doesn't seem right",
    selfLink: "Same link",
    selectOption: "Select an option",
    createInvoice: "CREATE INVOICE",
    pay: "PAY",
    processing: "Payed - In processing",
    awaitingPayment: "Awaiting payment",
    completed: "Completed",
    expiredPayment: "Expired",
    offline: "Temporarily offline"
}