import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { TEXTS } from '../../Resources/text';
import {FormContainer, FormGroup, ButtonStyled } from './styles';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { getItems } from '../../Services/itemsService';
import { isWorkingService, verifyLink } from '../../Services/utilsService';
import { createInvoiceService } from '../../Services/invoiceService';


import { useInitData } from '@vkruglikov/react-telegram-web-app';
import easterEgg from '../../Resources/art';


const FormPayment = () => {
  easterEgg()
  const [payerLink, setPayerLink] = useState('');
  const [receiverLink, setReceiverLink] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [areFieldsEmpty, setAreFieldsEmpty] = useState(true);
  const [payerLinkIsValid, setPayerLinkIsValid] = useState(null);
  const [receiverLinkIsValid, setReceiverLinkIsValid] = useState(null);
  const [isSelfLink, setIsSelfLink] = useState(false);
  const [options, setOptions] = useState([]);
  const [apiWorking, setApiWorking] = useState(true);
  let navigate = useNavigate();

  const [initDataUnsafe] = useInitData();

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: '#282a36',
      height: 45,
      borderColor: selectedOption == null ? "#bd93f9" : "#50fa7b",
      // boxShadow: state.isFocused ? '0 0 0 1px #50fa7b' : '#1E3A5C',
      '&:hover': {
        borderColor: selectedOption == null ? "#bd93f9" : "#50fa7b",

      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#f1fa8c' : '#282a36',
      
      color: state.isFocused ? 'black' : 'white',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: 'white',
    }),
  
    menu: (provided, state) => ({
      ...provided,
      borderRadius: 10,
      // kill the gap
      marginTop: 0
    }),
  
    menuList: (provided, state) => ({
      ...provided,
      borderRadius: 7,
      padding: 0,  
    }),
  };
  
  


  const handleSelectChange = (selected) => {
    console.log(selected)
    setSelectedOption(selected);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // console.log('Input 1:', payerLink);
    // console.log('Input 2:', receiverLink);
    // console.log('Selected Option:', selectedOption.value);
    const id = toast.loading('Creating invoice... ⏳', {
        position: "top-right",
        });


      const response = await createInvoiceService({
          payerLink: payerLink,
          receiverLink: receiverLink,
          itemId: selectedOption.value,
          telegramId:  initDataUnsafe.user == undefined ? null : initDataUnsafe.user.id
        })
      

      switch (response.statusCode) {
        case 201:
          const invoiceId = response.response.invoiceId
          toast.update(id, {render: "Invoice created", type: "success", isLoading: false});
          navigate("/invoice/" + invoiceId)
          break;
        case 409:
         toast.update(id, {render: "Player already has an open invoice", type: "warning", isLoading: false, autoClose: 4000});
         break;
        default:
          toast.update(id, {render: "temporarily offline", isLoading: false, autoClose: 4000});
          break;
      }
   
  };



  async function isRivalRegionsURL(url) {
    if(url.length >= 10){
      const response = await verifyLink(url);
      return response.response.data.isValid;
    }
    return false;
  }


  const handleChange = async (event, setterContent, setterValid) => {
    event.preventDefault();
    setterContent(event.target.value)
    if (event.target.value == ""){
      setterValid(null);
    }else{
      setterValid(await isRivalRegionsURL(event.target.value));
    }
    
  };


  async function loadItems () {

    const isWorking = await isWorkingService()
    setApiWorking(isWorking);

    if (isWorking) {

      const response = await getItems();
      
      setOptions(response.response.data.items.map(item => ({
        value: item.id,
        label: item.name
      })));
    }


  }

  useEffect(() => {

    loadItems();
    
  }, []);

  useEffect(() => {
    // Verifica se pelo menos um dos campos está vazio
    setAreFieldsEmpty(!(payerLinkIsValid == true && receiverLinkIsValid == true && selectedOption !== null));
  }, [payerLink, selectedOption, receiverLink, payerLinkIsValid, receiverLinkIsValid, isSelfLink]);

  return (
    
    <FormContainer>

    {apiWorking ? <h2>RR STORE BOT</h2> : <h1>{TEXTS.offline}</h1>}
   
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Form.Control
            type="text"
            placeholder={TEXTS.payerLink}
            value={payerLink}
            isValid={payerLinkIsValid == null ? false : payerLinkIsValid}
            isInvalid={payerLinkIsValid == null ? false : !payerLinkIsValid}
            onChange={(e) => handleChange(e, setPayerLink, setPayerLinkIsValid)}
            disabled={!apiWorking}
            style={{ backgroundColor: '#282a36', color: 'white', height: 45 }}
          />
          <Form.Control.Feedback type="valid">{TEXTS.validLink}</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">{TEXTS.invalidLink}</Form.Control.Feedback>
        </FormGroup>
        <FormGroup>
          <Select
            styles={customStyles}
            options={options}
            // value={selectedOption.value}
            onChange={(selected)=>handleSelectChange(selected)}
            placeholder={TEXTS.selectOption}
          />
        </FormGroup>
        <FormGroup>
          <Form.Control
            type="text"
            placeholder={TEXTS.payerLink}
            disabled={isSelfLink || payerLinkIsValid == null ? true : !payerLinkIsValid}
            value={isSelfLink ? payerLink : receiverLink}
            isValid={receiverLinkIsValid == null ? false :receiverLinkIsValid}
            isInvalid={receiverLinkIsValid == null ? false : !receiverLinkIsValid}
            onChange={(e) => handleChange(e, setReceiverLink, setReceiverLinkIsValid)}
            // onPaste={(e) => handleChange(e, setReceiverLink, setReceiverLinkIsValid)}
            style={{ backgroundColor: '#282a36', color: 'white', height: 45 }}
          />
          <Form.Control.Feedback type="valid">{TEXTS.validLink}</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">{TEXTS.invalidLink}</Form.Control.Feedback>

        </FormGroup>
        <FormGroup>
          <Form.Check
              inline
              label={TEXTS.selfLink}
              name="selfLink"
              disabled={!payerLinkIsValid}
              onChange={() => {

                              setIsSelfLink(!isSelfLink); 
                              setReceiverLink(isSelfLink ? payerLink : ""); 
                              setReceiverLinkIsValid(isSelfLink ? setReceiverLinkIsValid(payerLinkIsValid) : isRivalRegionsURL(""))}}
            />
        </FormGroup>
        <ButtonStyled type="submit" disabled={!(payerLinkIsValid && receiverLinkIsValid && selectedOption !== null)}> {TEXTS.createInvoice} </ButtonStyled>
      </Form>
      <ToastContainer autoClose={900000000} />
    </FormContainer>
  );
};

export default FormPayment;
