import React, { useEffect, useState } from 'react';
import { Badge, Card, Col, Container, Row, Table } from 'react-bootstrap';
import { Header } from '../Header/Header';
import { ButtonStyled } from './styles';
import { TEXTS } from '../../Resources/text';
import { FiCopy } from "react-icons/fi";
import { getItems } from '../../Services/itemsService';
import { useParams } from 'react-router-dom';
import { getInvoiceService } from '../../Services/invoiceService';
import { getBankAccount } from '../../Services/utilsService';
import easterEgg from '../../Resources/art';


const InvoiceComponent = () => {
  easterEgg();
  const {invoiceId} = useParams();
  const [payerLink, setPayerLink] = useState();
  const [receiverLink, setReceiverLink] = useState();
  const [payerName, setPayerName] = useState();
  const [receiverName, setReceiverName] = useState();
  const [invoiceStatus, setInvoiceStatus] = useState('');
  const [copied, setCopied] = useState(false);
  const [linkToPay, setLinkToPay] = useState("LINK PARA PAGAR");
  const [itemName, setItemName] = useState();
  const [itemPrice, setItemPrice] = useState();
  const [createDate, setCreateDate] = useState();
  const [dueDate, setDueDate] = useState();
  const [bankAccountUrl, setBankAccountUrl] = useState("");
  const statusInvoiceBadge = (status) => {

    switch (status) {
      case "PROCESSING":
        return <Badge bg="warning">{TEXTS.processing}</Badge>
      case "PAYED":
        return <Badge bg="warning">{TEXTS.processing}</Badge>
      case "AWAITING":
        return <Badge bg="warning">{TEXTS.awaitingPayment}</Badge>
      case "EXPIRED":
        return <Badge bg="danger">{TEXTS.expiredPayment}</Badge>
      case "ERROR":
        return <Badge bg="danger">ERROR</Badge>
      case "COMPLETED":
          return <Badge bg="success">{TEXTS.completed}</Badge>
      default:
        break;
    }
  }

  function copyToClipboard() {

    navigator.clipboard.writeText(bankAccountUrl)
    setCopied(true);
  };


  const convertDate = (date) =>{


    var data = new Date(date);

    var ano = data.getUTCFullYear();
    var mes = (data.getUTCMonth() + 1).toString().padStart(2, "0");
    var dia = data.getUTCDate().toString().padStart(2, "0");
    var hora = data.getUTCHours().toString().padStart(2, "0");
    var minuto = data.getUTCMinutes().toString().padStart(2, "0");
    var segundo = data.getUTCSeconds().toString().padStart(2, "0");

    var dataFormatada = ano + "-" + mes + "-" + dia + " " + hora + ":" + minuto + ":" + segundo;
    return dataFormatada;
  }

  const loadInvoiceInfo = async () =>{
    const bankAccount = await getBankAccount();
    const response = await getInvoiceService({
      invoiceId: invoiceId
    })
    setBankAccountUrl(bankAccount.response.url);
    setPayerLink(response.response.invoice.payerLink)
    setReceiverLink(response.response.invoice.receiverLink)
    setPayerName(response.response.invoice.payerName)
    setReceiverName(response.response.invoice.receiverName)
    setItemName(response.response.item.name)
    setItemPrice(response.response.item.valueWithK)
    setCreateDate(convertDate(response.response.invoice.createdAt))
    setDueDate(convertDate(response.response.invoice.expiredAt))
    setInvoiceStatus(response.response.invoice.status)
  }

  const timeout = () => {
    // Coloque o código que deseja executar a cada 5 segundos aqui
    //console.log('Este código será executado a cada 5 segundos.');
    loadInvoiceInfo();

    // Agendar a próxima execução após 5 segundos
    setTimeout(timeout, 10000);
  };

  useEffect(() => {
    // Iniciar a primeira execução após a montagem do componente
    timeout();
    // Certifique-se de limpar o timeout quando o componente for desmontado
    return () => clearTimeout();
  }, []);

  useEffect(() => {
    loadInvoiceInfo();
    
  }, []);



  return (
    <Container style={{textAlign: "center", justifyContent: "center"}}>
      <Row >
        <Col xs={12} lg={10} xl={8}>
          <Header className="mt-md-5">
            <Header.Body style={{paddingBottom: 0}}>
              <Row className="align-items-center">
                <Col>
                  <Header.Pretitle>Payment</Header.Pretitle>
                  <Header.Title>Invoice #{invoiceId}</Header.Title>
                </Col>
               
              </Row>
              <Row style={{marginTop: 30}}>

                
              
                <Col>
                    <Header.Subtitle>
                      <span>
                          Click on 'Pay' to be redirected instantly or click on 'Copy icon' to get the account link to be paid
                      </span><FiCopy style={{marginLeft: 10}} onClick={copyToClipboard} />     {copied ? <h6>copied</h6> : <></>}
                    </Header.Subtitle>
                </Col>
                
              </Row>
              <Row style={{justifyContent: 'center', marginTop:"1.5rem"}}>
              <Col xs="auto">
                    <ButtonStyled onClick={() => window.open(bankAccountUrl, "_blank")}>{TEXTS.pay}</ButtonStyled>
                  </Col>
              </Row>
            </Header.Body>
          </Header>
          <Card>
            <Card.Body className="p-5">
              <Row>
                <Col className="text-end">
                  {statusInvoiceBadge(invoiceStatus)}

                </Col>
              </Row>
              <Row>
                <Col className="text-left">
                  <img className="img-fluid mb-4" src="/img/logo.svg" alt="..." style={{ maxWidth: 40 }} />
                  <h2 className="mb-2">Invoice from RR Market Bot</h2>
                  <p className="text-muted mb-6">Invoice #{invoiceId}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <h5 className="text-uppercase text-muted">From</h5>
                  <p className="text-muted mb-4">
                    <strong className="text-body">{payerName}</strong> <br />
                    {payerLink} <br />
                  </p>
                  {/* <h6 className="text-uppercase text-muted">Invoiced ID</h6>
                  <p className="mb-4">#SDF9823KD</p> */}
                </Col>
                <Col xs={12} md={6}>
                  <h5 className="text-uppercase text-muted">To</h5>
                  <p className="text-muted mb-4">
                    <strong className="text-body">{receiverName}</strong> <br />
                    {receiverLink} <br />
                  </p>
                
                </Col>
              </Row>
              <Row style={{marginTop: 15}}>
              <Col xs={12} md={6}>
              <h4 className="text-uppercase text-muted">Created order</h4>
                <p className="mb-4">
                  <time>{createDate}</time>
                </p>
              </Col>
              <Col xs={12} md={6}>
              <h4 className="text-uppercase text-muted">Due date</h4>
                <p className="mb-4">
                  <time>{dueDate}</time>
                </p>
              </Col>
              </Row>
              <Row style={{textAlign: "left", justifyContent: "center"}}>
                <Col xs={10}>
                  <Table className="my-4" responsive>
                    <thead>
                      <tr>
                        <th className="px-0 bg-transparent border-top-0">
                          <span className="h5">Description</span>
                        </th>
                        <th className="px-0 bg-transparent border-top-0 text-end">
                          <span className="h5">Cost</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="px-0">{itemName}</td>
                        <td className="px-0 text-end">{itemPrice}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>

  );
};

export default InvoiceComponent;
