import api from './api';

export const createInvoiceService = async ({payerLink, receiverLink, itemId, telegramId}) => {
    try {

        const response = await api.post("/invoice", {
            "payerLink": payerLink,
            "receiverLink": receiverLink,
            "itemId": itemId, 
            "telegramId": telegramId
        });
        if (response.status === 201){
            return {"response": response.data.data, "statusCode": response.status}
        }
        
    } catch (error) {
        // console.log(error)
        return {"response": error.response.data, "statusCode": error.response.status}

    }
}


export const getInvoiceService = async ({invoiceId}) => {
    try {

        const response = await api.get(`/invoice?invoiceId=${invoiceId}`);
        if (response.status === 200){
            return {"response": response.data.data, "statusCode": response.status}
        }
        
    } catch (error) {
        // console.log(error)
        return {"response": error.response.data, "statusCode": error.response.status}

    }
}
