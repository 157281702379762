import logo from './logo.svg';
import './App.css';
import FormPayment from './pages/formPayment';
import InvoicePage from './pages/invoice';
import RoutesA from './routes';
import { BrowserRouter } from 'react-router-dom';
function App() {
  return (
    <BrowserRouter>
      <RoutesA/>
    </BrowserRouter>
    // <div className="App">

    //   <InvoicePage />
     
    // </div>
  );
}

export default App;
