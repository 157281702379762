import api from './api';

export const getItems = async () => {
    try {

        const response = await api.get("/items");
        if (response.status === 200){
            return {"response": response.data, "statusCode": response.status}
        }
        
    } catch (error) {

        return {"response": error.response.data, "statusCode": error.response.status}

    }
}
