import axios from 'axios';

const api = axios.create({

  baseURL: "http://localhost:5000",

});

// api.defaults.headers['Content-Type'] = 'application/json';
api.defaults.headers["Api-Key"] = "E7kA6734lTBenxtxfAjwvyVXkQXX2UvmOTO1"



export default api;