import api from './api';

export const verifyLink = async (link) => {
    try {

        const response = await api.post("/utils/valid", {
            "link": link
        });
        if (response.status === 200){
            return {"response": response.data, "statusCode": response.status}
        }
        
    } catch (error) {
        // console.log(error)
        return {"response": error.response.data, "statusCode": error.response.status}

    }
}


export const isWorkingService = async () => {

    try {

        const response = await api.get("/status");
        if (response.status === 200){
            return true
        }else{
            return false
        }
        
    } catch (error) {
        // console.log(error)
        return false

    }

}


export const getBankAccount = async () => {

    try {

        const response = await api.get("/utils/bankAccount");
        if (response.status === 200){
            return {"response": response.data, "statusCode": response.status}
        }
        
    } catch (error) {
        // console.log(error)
        return {"response": error.response.data, "statusCode": error.response.status}

    }

}