import React, { useEffect, useState } from 'react';
import InvoiceComponent from '../components/invoice/invoice';



const InvoicePage = () => {


  return (
    <div className="App">
        <div className="main-content">
            <InvoiceComponent/>
      </div>
   </div>
  );
};

export default InvoicePage;
